import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import { RegistrationPlatformRoutingModule } from './registration-platform-routing.module';
import { RegistrationPlatformListComponent } from './registration-platform-list/registration-platform-list.component';
import { RegistrationPlatformAddComponent } from './registration-platform-add/registration-platform-add.component';
import {DataTablesModule} from 'angular-datatables';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '@app/modules/shared/shared.module';
import { MatMenuModule } from '@angular/material/menu';
import { PopoverModule } from 'ngx-bootstrap/popover';

@NgModule({
  declarations: [
    RegistrationPlatformListComponent,
    RegistrationPlatformAddComponent
  ],
    imports: [
        CommonModule,
        NgbPopoverModule,
        FormsModule,
        ReactiveFormsModule,
        DataTablesModule,
        RegistrationPlatformRoutingModule,
        SharedModule,
        MatMenuModule,
        PopoverModule.forRoot(),
    ]
})
export class RegistrationPlatformModule { }
