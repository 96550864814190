import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, Output} from '@angular/core';
import {Validators, UntypedFormGroup, UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {Client} from '@app/modules/shared/models';
import {ActivatedRoute, Router} from '@angular/router';
import {Constants} from '@app/consts';
import {
  IRegistrationPlatformState,
  RegistrationPlatformCreate,
  getRegistrationPlatform,
  ResetRegistrationPlatformState,
  RegistrationPlatformGet,
  RegistrationPlatformUpdate,
  getRegistrationPlatformError,
  RegistrationPlatformGetSuccess,
  getTemplateSectionAssets,
  ITemplateSectionState,
  getToken,
  IAuthenticationState,
  getVariableError,
  getVariables,
  IVariableState,
  TemplateSectionGetAssets,
  VariableList,
  getSignedUrlError,
  getUploadToSignedUrlError,
  getFileUploadProgress,
  getSignedUrl,
  getUploadToSignedUrl,
  IFileUploadState,
  SignedUrl,
  UploadToSignedUrl,
  RegistrationPlatformValidateSlug,
  getRegistrationPlatformSlug
} from '@app/stores';
import {select, Store} from '@ngrx/store';
import {takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {environment} from '@environments/environment';
import {TemplateSectionService} from '@app/services/template-section.service';
declare var FroalaEditor: any;
function getCodeMirror() {
  // @ts-ignore
  return window.CodeMirror;
}

@Component({
  selector: 'app-registration-platform-add',
  templateUrl: './registration-platform-add.component.html',
  styleUrls: ['./registration-platform-add.component.css'],
  providers: [Constants]
})
export class RegistrationPlatformAddComponent implements OnInit, OnDestroy, AfterViewInit {
  addRegistrationPlatformForm: UntypedFormGroup;
  unsubscriber = new Subject();
  clients: Client[] = [];
  platformId: number;
  submitted = false;
  public editor: any;
  public apiEditor: any;
  isAdmin = false;
  editorLoaded = false;
  apiGuideEditorLoaded = false;
  currentUser: any;
  platform: any = null;
  token = null;
  public variables = {};
  isLoadEditor = false;
  isApiGuideLoadEditor = false;
  imageFile = null;
  imageSignedUrl = null;
  fileUploadProgress = 0;
  imageBinary: any;
  slugData: String;
  selectedWidgetSupport = null;
  selectedSyncSupport = null;
  widgetSupportValues = [{id: 'basic', name: 'Minimum (Clients might need help and it may not always work)'},
    {id: 'full', name: 'Full (Clients can easily add without any help and works but there are workarounds)'},
    {id: 'ultimate', name: 'Ultimate (Clients can easily add without any help and works reliably)'}];
  syncSupportValues = [{id: 'basic', name: 'Basic (Speakers Only)'},
    {id: 'full', name: 'Full (All Categories)'}];

  constructor( private router: Router,
               private formBuilder: UntypedFormBuilder,
               public constants: Constants,
               private variableStore: Store<IVariableState>,
               private registrationPlatformStore: Store<IRegistrationPlatformState>,
               private toastrService: ToastrService,
               private authenticationStore: Store<IAuthenticationState>,
               private templateSectionService: TemplateSectionService,
               // private templateSectionStore: Store<ITemplateSectionState>,
               private fileUploadStore: Store<IFileUploadState>,
               private readonly cdr: ChangeDetectorRef,
               private toastr: ToastrService,
               private route: ActivatedRoute) {
    this.registrationPlatformStore.dispatch(ResetRegistrationPlatformState({params: {error: '', registrationPlatform: null, success: ''}}));
    this.authenticationStore.pipe(select(getToken))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(newToken => {
        if (newToken) {
          this.token = newToken;
        }
      });
    this.subscribeStores();
  }

  subscribeStores() {
    this.variableStoreSubscribe();
    this.registrationPlatformStore.pipe(select(getRegistrationPlatformError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error, 'Error');
        }
      });
    this.registrationPlatformStore.pipe(select(RegistrationPlatformGetSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.submitted) {
            this.submitted = false;
          }
        }
      });
    // this.templateSectionStore.pipe(select(getTemplateSectionAssets))
    //   .pipe(takeUntil(this.unsubscriber))
    //   .subscribe(templateSectionAssets => {
    //     if (templateSectionAssets) {
    //       if (Object.keys(this.variables).length > 0) {
    //         this.enableEditor(templateSectionAssets.assets?.css);
    //         this.enableApiEditor(templateSectionAssets.assets?.css);
    //       } else {
    //         setTimeout(() => {
    //           this.enableEditor(templateSectionAssets.assets?.css);
    //           this.enableApiEditor(templateSectionAssets.assets?.css);
    //         }, 1000);
    //       }
    //     }
    //   });
    this.registrationPlatformStore.pipe(select(getRegistrationPlatform))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(platform => {
        if (platform) {
          this.platform = Object.assign({}, platform);
          this.addRegistrationPlatformForm.patchValue({
            name: platform.name,
            website: platform.website,
            image: platform.image,
            settings: platform.settings,
            api_integration_guide: platform.api_integration_guide,
            widget_customization_code: platform.widget_customization_code,
            widget_customization_css: platform.widget_customization_css,
            hide_default_guide: platform.hide_default_guide === true ? 1 : 0,
          });
          this.selectedWidgetSupport = platform.widget_support || null;
          this.selectedSyncSupport = platform.sync_support || null;
          this.imageBinary = platform.image;
          this.patchData(platform.widget_integration_guide);
          this.patchApiGuideData(platform.api_integration_guide);
        }
      });

    this.registrationPlatformStore.pipe(select(getRegistrationPlatformSlug))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(slug => {
        if (slug && slug === 'invalid') {
          this.addRegistrationPlatformForm.controls['slug'].setValue('');
          this.toastr.error('Please change the slug, it\'s already taken', 'Error');
        }
      });
    this.fileStoreSubscribe();
  }

  fileStoreSubscribe() {
    this.fileUploadStore.pipe(select(getSignedUrlError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.fileUploadStore.pipe(select(getUploadToSignedUrlError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.fileUploadStore.pipe(select(getFileUploadProgress))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data !== undefined && data !== null) {
          this.fileUploadProgress = data;
        }
      });
    this.fileUploadStore.pipe(select(getSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && data.url) {
          this.imageSignedUrl = data.url;
        }
      });
    this.fileUploadStore.pipe(select(getUploadToSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && this.imageSignedUrl) {
          const image = this.imageSignedUrl.split('?')[0];
          this.addRegistrationPlatformForm.patchValue({image});
          this.imageSignedUrl = null;
          this.imageFile = null;
          this.saveRegistrationPlatformData();
        }
      });
  }

  variableStoreSubscribe() {
    this.variableStore.pipe(select(getVariableError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error, 'Error');
        }
      });
    this.variableStore.pipe(select(getVariables))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(variables => {
        if (variables) {
          const result = [];
          Object.entries(variables).forEach(([key, value]) => {
            if (value.hasOwnProperty('group') && value['group']) {
              if (!result[value['group']]) {
                result[value['group']] = [];
              }
              result[value['group']].push({
                name: value['title'].replace(value['group'].toString().toLowerCase().trim(), ''),
                key: value['alias'] ? `#${value['alias']}#` : value['key'],
                valueType: value['valueType']
              });
            } else {
              if (!result['generic']) {
                result['generic'] = [];
              }
              result['generic'].push({
                name: value['title'],
                key: value['key'],
                valueType: value['valueType']
              });
            }
          });
          this.variables = result;
        }
      });
  }

  ngOnInit(): void {
    this.enableEditor();
    this.enableApiEditor();
    this.route.paramMap.subscribe(params => {
      if (params.has('id')) {
        this.platformId = +params.get('id');
        this.loadRegistrationPlatform(this.platformId);
      }
    });
    this.addRegistrationPlatformForm = this.formBuilder.group({
      name: ['', Validators.required],
      image: [''],
      website: ['', Validators.pattern(this.constants.urlRegex)],
      settings: [''],
      api_integration_guide: [''],
      widget_customization_code: [''],
      widget_customization_css: [''],
      hide_default_guide: 0,
    });
    if (!this.platformId) {
      this.addRegistrationPlatformForm.addControl('slug',  new UntypedFormControl('', Validators.required)); // Add new form control
    }
  }

  ngAfterViewInit() {
    // this.getAssets();
    // this.variableStore.dispatch(VariableList({params: {}}));
    this.cdr.detectChanges();
  }

  get f() {
    return this.addRegistrationPlatformForm.controls;
  }

  // getAssets() {
  //   this.templateSectionStore.dispatch(TemplateSectionGetAssets({params: {html_mode: 'editor'}}));
  // }

  saveRegistrationPlatform() {
    this.submitted = true;
    if (this.addRegistrationPlatformForm.invalid) {
      return;
    }
    this.registrationPlatformStore.dispatch(RegistrationPlatformGetSuccess({registrationPlatform: null}));
    if (this.imageSignedUrl && this.imageFile) {
      this.fileUploadStore.dispatch(UploadToSignedUrl({files: [{url: this.imageSignedUrl, fileData: this.imageFile}]}));
    } else {
      this.saveRegistrationPlatformData();
    }
  }
  setSyncSupport(e) {
    this.selectedSyncSupport = e?.value ? e.value : null;
  }
  setWidgetSupport(e) {
    this.selectedWidgetSupport = e?.value ? e.value : null;
  }

  saveRegistrationPlatformData() {
    const saveParams = this.addRegistrationPlatformForm.value;
    console.log('saveParams', saveParams);

    saveParams['widget_support'] = this.selectedWidgetSupport?.length ? this.selectedWidgetSupport : null;
    saveParams['sync_support'] = this.selectedSyncSupport?.length ? this.selectedSyncSupport : null;

    let htmlBody: any = this.getHtml();
    let apiGuideHtmlBody: any = this.getApiGuideHtml();

    if (htmlBody === '') {
      saveParams['widget_integration_guide'] = null;
    } else {
      htmlBody = htmlBody.replaceAll(/data-id="[^"]*"/g, '');
      htmlBody = htmlBody.replaceAll(' fp-active', '');
      htmlBody = htmlBody.replaceAll('fp-active', '');
      saveParams['widget_integration_guide'] = htmlBody;
    }

    if (apiGuideHtmlBody === '') {
      saveParams['api_integration_guide'] = null;
    } else {
      apiGuideHtmlBody = apiGuideHtmlBody.replaceAll(/data-id="[^"]*"/g, '');
      apiGuideHtmlBody = apiGuideHtmlBody.replaceAll(' fp-active', '');
      apiGuideHtmlBody = apiGuideHtmlBody.replaceAll('fp-active', '');
      saveParams['api_integration_guide'] = apiGuideHtmlBody;
    }
    if (this.platformId) {
      this.registrationPlatformStore.dispatch(RegistrationPlatformUpdate({registrationPlatformId: this.platformId, registrationPlatform: saveParams}));
    } else {
      this.registrationPlatformStore.dispatch(RegistrationPlatformCreate({registrationPlatform: saveParams}));
    }
    this.cancelClick();
  }

  onChangeCheckbox (event, slug) {
    const isChecked = event.target.checked;
    if (slug === 'hide_default_guide') {
      this.addRegistrationPlatformForm.patchValue({
        hide_default_guide: isChecked ? 1 : 0
      });
    }
    // if (slug === 'widget_support') {
    //   this.addRegistrationPlatformForm.patchValue({
    //     widget_support: isChecked ? 1 : 0
    //   });
    // }
    // if (slug === 'sync_support') {
    //   this.addRegistrationPlatformForm.patchValue({
    //     sync_support: isChecked ? 1 : 0
    //   });
    // }
  }

  loadRegistrationPlatform(registrationPlatformId: number) {
      this.registrationPlatformStore.dispatch(RegistrationPlatformGet({registrationPlatformId}));
  }

  cancelClick() {
      this.router.navigate(['settings/registration-platform']);
  }

  enableEditor(css: any = {}) {
    const that = this;
    // this.templateSectionService.addCustomVariables(FroalaEditor, this.variables);
    const editorOptions = {
      key: environment.froalaEditorKey,
      iframe: true,
      iframeStyleFiles: css,
      attribution: false,
      charCounterCount: false,
      htmlUntouched: true,
      imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
      imageUploadURL: environment.apiUrl + '/api/v1/file',
      fileUploadURL: environment.apiUrl + '/api/v1/file',
      height: 'auto',
      heightMax: 'auto',
      heightMin: 400,
      codeMirror: getCodeMirror(),
      codeMirrorOptions: {
        indentWithTabs: true,
        lineNumbers: true,
        lineWrapping: true,
        mode: 'text/html',
        tabMode: 'indent',
        tabSize: 2
      },
      requestHeaders: {
        'Authorization': `Bearer ${this.token}`
      },
      events: {
        'initialized': function () {
          that.editorLoaded = true;
          that.changeContent('section');
        },
        'image.uploaded': function (response) {
          const jsonResponse = JSON.parse(response);
          this.image.insert(jsonResponse.data.url, false, null, this.image.get(), response);
          return false;
        },
        'file.uploaded': function (response) {
          const jsonResponse = JSON.parse(response);
          this.file.insert(jsonResponse.data.url, jsonResponse.data.file, null);
          return false;
        },
      },
      htmlAllowedEmptyTags: ['textarea', 'a', 'iframe', 'object', 'video', 'style', 'script', '.fa', '.fr-emoticon', '.fr-inner', 'path', 'line', 'hr', 'i', 'span', 'div'],
      linkInsertButtons: ['linkBack', '|', 'linkList', 'linkUrlVariables', 'linkTextVariables'],
      pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'cryptoJSPlugin', 'draggable', 'emoticons', 'entities',
        'file', 'filesManager', 'fontAwesome', 'fontFamily', 'fontSize', 'forms', 'fullscreen', 'help', 'image', 'imageManager', 'imageVariable',
        'inlineClass', 'inlineStyle', 'lineBreaker', 'lineHeight', 'link', 'lists', 'markdown', 'paragraphFormat', 'paragraphStyle',
        'print', 'quickInsert', 'quote', 'save', 'specialCharacters', 'table', 'url', 'video', 'wordPaste', 'html'],
      imageEditButtons: ['imageReplace', 'imageVariableButton', 'imageAlign', 'imageCaption', 'imageRemove', 'imageLink', 'linkOpen',
        'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'],
      toolbarButtons: ['bold', 'italic', 'textColor', 'backgroundColor', 'paragraphFormat', 'align', 'emoticons', 'insertLink',
        'insertImage', 'undo', 'redo', '-', 'underline', 'subscript', 'superscript', 'fontSize', 'fontFamily', 'insertTable', 'formatOL', 'formatUL',
        'lineHeight', 'insertVideo', 'fontAwesome', 'inlineClass', 'inlineStyle', 'insertFile', 'html']
    };
    this.editor = new FroalaEditor('#editor1', editorOptions);
    if (Object.keys(this.editor).length > 0) {
      this.addCssToFroala();
    } else {
      setTimeout(() => {
        this.editor = new FroalaEditor('#editor1', editorOptions);
      }, 1000);
    }
  }


  enableApiEditor(css: any = {}) {
    const that = this;
    // this.templateSectionService.addCustomVariables(FroalaEditor, this.variables);
    const editorOptions = {
      key: environment.froalaEditorKey,
      iframe: true,
      iframeStyleFiles: css,
      attribution: false,
      charCounterCount: false,
      htmlUntouched: true,
      imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
      imageUploadURL: environment.apiUrl + '/api/v1/file',
      fileUploadURL: environment.apiUrl + '/api/v1/file',
      height: 'auto',
      heightMax: 'auto',
      heightMin: 400,
      codeMirror: getCodeMirror(),
      codeMirrorOptions: {
        indentWithTabs: true,
        lineNumbers: true,
        lineWrapping: true,
        mode: 'text/html',
        tabMode: 'indent',
        tabSize: 2
      },
      requestHeaders: {
        'Authorization': `Bearer ${this.token}`
      },
      events: {
        'initialized': function () {
          console.log('=090990 editor 2 initialized');
          that.apiGuideEditorLoaded = true;
          that.changeApiGuideContent('section');
        },
        'image.uploaded': function (response) {
          const jsonResponse = JSON.parse(response);
          this.image.insert(jsonResponse.data.url, false, null, this.image.get(), response);
          return false;
        },
        'file.uploaded': function (response) {
          const jsonResponse = JSON.parse(response);
          this.file.insert(jsonResponse.data.url, jsonResponse.data.file, null);
          return false;
        },
      },
      htmlAllowedEmptyTags: ['textarea', 'a', 'iframe', 'object', 'video', 'style', 'script', '.fa', '.fr-emoticon', '.fr-inner', 'path', 'line', 'hr', 'i', 'span', 'div'],
      linkInsertButtons: ['linkBack', '|', 'linkList', 'linkUrlVariables', 'linkTextVariables'],
      pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'cryptoJSPlugin', 'draggable', 'emoticons', 'entities',
        'file', 'filesManager', 'fontAwesome', 'fontFamily', 'fontSize', 'forms', 'fullscreen', 'help', 'image', 'imageManager', 'imageVariable',
        'inlineClass', 'inlineStyle', 'lineBreaker', 'lineHeight', 'link', 'lists', 'markdown', 'paragraphFormat', 'paragraphStyle',
        'print', 'quickInsert', 'quote', 'save', 'specialCharacters', 'table', 'url', 'video', 'wordPaste', 'html'],
      imageEditButtons: ['imageReplace', 'imageVariableButton', 'imageAlign', 'imageCaption', 'imageRemove', 'imageLink', 'linkOpen',
        'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'],
      toolbarButtons: ['bold', 'italic', 'textColor', 'backgroundColor', 'paragraphFormat', 'align', 'emoticons', 'insertLink',
        'insertImage', 'undo', 'redo', '-', 'underline', 'subscript', 'superscript', 'fontSize', 'fontFamily', 'insertTable', 'formatOL', 'formatUL',
        'lineHeight', 'insertVideo', 'fontAwesome', 'inlineClass', 'inlineStyle', 'insertFile', 'html']
    };
    this.apiEditor = new FroalaEditor('#editor2', editorOptions);
    if (Object.keys(this.apiEditor).length > 0) {
      this.addApiCssToFroala();
    } else {
      setTimeout(() => {
        this.apiEditor = new FroalaEditor('#editor2', editorOptions);
      }, 1000);
    }
  }

  addCssToFroala() {
    const css = this.editor.$iframe[0].contentWindow.document.createElement('style');
    css.innerHTML = this.templateSectionService.getFroalaCss();
    this.editor.$iframe[0].contentWindow.document.head.appendChild(css);
    this.isLoadEditor = true;
  }

  addApiCssToFroala() {
    const css = this.apiEditor.$iframe[0].contentWindow.document.createElement('style');
    css.innerHTML = this.templateSectionService.getFroalaCss();
    this.apiEditor.$iframe[0].contentWindow.document.head.appendChild(css);
    this.isApiGuideLoadEditor = true;
  }

  changeContent(type = '') {
        this.editor.html.set('');
  }

  changeApiGuideContent(type = '') {
        this.apiEditor.html.set('');
  }

  getHtml() {
    return this.editor.html.get(true);
  }

  getApiGuideHtml() {
    return this.apiEditor.html.get(true);
  }

  patchData(htmlBody) {
      if (this.editorLoaded) {
        htmlBody ? this.editor.html.set(htmlBody) : this.changeContent();
      } else {
        setTimeout(() => {
          this.patchData(htmlBody);
        }, 1000);
      }
    }


    patchApiGuideData(htmlBody) {
      if (this.apiGuideEditorLoaded) {
        htmlBody ? this.apiEditor.html.set(htmlBody) : this.changeApiGuideContent();
      } else {
        setTimeout(() => {
          this.patchApiGuideData(htmlBody);
        }, 1000);
      }
    }

  uploadLogo(fileInput) {
    fileInput.click();
  }

  onEventImageChanged(event) {
    if (event.target.files && event.target.files.length) {
      this.imageFile = event.target.files[0];
      this.fileUploadStore.dispatch(SignedUrl({fileName: this.imageFile.name, contentType: this.imageFile.type}));
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(this.imageFile);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }).then(data => {
        this.imageBinary = data;
      });
    }
  }

  generateSlug(): void {
    this.slugData = this.convertToSlug(this.addRegistrationPlatformForm.get('name').value);
    this.addRegistrationPlatformForm.get('slug').setValue(this.slugData);
  }

  convertToSlug(Text) {
    return Text.toLowerCase().replace(/[^\w ]+/g, '').trim().replace(/  +/g, ' ').replace(/ +/g, '-');
  }

  public validateSlug() {
    if (!this.addRegistrationPlatformForm.get('slug').value) {
      return;
    }
    this.registrationPlatformStore.dispatch(RegistrationPlatformValidateSlug({slug: this.addRegistrationPlatformForm.get('slug').value}));
  }

  ngOnDestroy() {
    this.editor.destroy();
    this.apiEditor.destroy();
    this.unsubscriber.next(undefined);
    this.unsubscriber.complete();
  }
}
